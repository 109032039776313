.choco_Butter {
  width: 100%;
  .choco_hero {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    img {
      width: 100%;
      height: 100%;
    }
    .choco_text {
      padding: 100px;
      background-color: rgba(0, 0, 0, 0.632);

      h1 {
        color: white !important;
        padding-bottom: 10px;
      }
      strong {
        color: white !important;
        padding-bottom: 10px;
      }
      p {
        color: white !important;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .choco_hero {
      grid-template-columns: 1fr;
      text-align: center;
      .choco_text {
        padding: 50px !important;
        text-align: center;
        strong {
          margin-bottom: 10px;
        }
      }
    }
  }
  .pro_section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .pro_img {
      width: 100%;
      img {
        width: 100%;
        padding: 50px;
      }
    }
    .pro_text {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 50px;

      h1 {
        color: white !important;
        padding-bottom: 20px;
      }
      strong {
        color: white !important;
      }
      p {
        color: white !important;
      }
    }
    .choco_shop {
      padding: 20px 0px;

      .btn {
        color: white;
        border: none;
        background: none;
        padding: 10px 30px;
        border-radius: 30px;
        border: 2px solid white;
      }
      .btn:hover {
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .pro_section {
      grid-template-columns: 1fr;
    }
    .pro_text {
      text-align: center;
      strong {
        margin-bottom: 10px;
      }
    }
    .item_one {
      order: 0;
    }
    .item_two {
      order: 1;
    }
    .item_three {
      order: 3;
    }
    .item_four {
      order: 2;
    }
    .item_five {
      order: 4;
    }
    .item_six {
      order: 5;
    }
    .item_seven {
      order: 7;
    }
    .itme_eight {
      order: 6;
    }
    .item_nine {
      order: 8;
    }
    .item_ten {
      order: 9;
    }
  }
}
