.coco_sug {
  width: 100%;
  max-width: 1400px;

  .coco_intro {
    width: 100%;
    height: auto;
    background-color: #159a56;

    .coco_intro_data {
      padding: 60px 100px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      h1 {
        color: white !important;
        padding-bottom: 10px;
        font-size: 3rem;
      }
      strong {
        padding: 1rem;
        font-size: 20px;
        font-weight: 300;
      }
      p {
        color: white;
        text-align: center;
      }
    }
    @media only screen and (max-width: 992px) {
      .coco_intro_data {
        padding: 50px;
        text-align: center;
      }
    }
  }
  .coco_be {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 80px 60px;
    width: 100%;
    height: auto;
    h1 {
      font-size: 2.5rem;
      color: #159a56;
      line-height: 1;
    }
    .coco_be_in {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;

      .coco_icon_con {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .coco_icon {
        padding: 30px;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      div {
        padding: 10px;
      }
      p {
        text-transform: uppercase;
      }
    }
    @media only screen and (max-width: 992px) {
      .coco_be_in {
        grid-template-columns: 1fr;
      }
      .coco_be {
        padding: 50px !important;
      }
      .coco_icon {
        padding: 5px !important;
      }
    }
    .coco_img {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .coco_info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 130px;
    grid-gap: 3rem;
    .coco_info_head {
      h1 {
        font-size: 3rem;
        line-height: 1;
        padding-bottom: 20px;
        color: #159a56;
      }
      strong {
        font-size: 20px;
        font-weight: 300;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .coco_info {
      grid-template-columns: 1fr;
      text-align: center;
      padding: 50px !important;
    }
  }
  .coco {
    width: 100%;
    height: 60vh;

    object-fit: contain;
    align-items: center;
    justify-content: center;
    display: flex;
    h1 {
      font-size: 5rem;
      line-height: 1;
      text-align: center;
      color: #159a56;
    }
  }

  .almond_ban {
    width: 100%;
    height: 400px;
    display: flex;
    background-color: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .shop_button {
      height: auto;
      padding: 20px 0px;
      .btn {
        color: rgb(255, 255, 255) !important;
        border: none;

        padding: 10px 30px;
        border-radius: 30px;
        border: 2px solid white;
      }
      .btn:hover {
        background-color: rgba(255, 255, 255, 0);
        color: rgb(7, 0, 0) !important;
        background: white;
      }
    }
  }
}
