.card {
  border: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  padding: 8px;

  small{
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: Arial, Helvetica, sans-serif !important;
  }


  .card_in{
    align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
    border: .5px solid rgb(242, 242, 242) !important;
    border-radius: 10px;
    padding: 10px;
    .image {
    object-fit: contain;
    height: 250px;
    margin: auto;
    width: 250px;
    position: relative;
    p{
      padding-bottom: 5px!important;
    }

    &:hover {
      .secondImg {
        z-index: 2;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .mainImg {
      z-index: 1;
    }

    span {
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: orange;
      color: rgb(255, 255, 255);
      padding: 3px 5px;
      z-index: 3;
      border-radius: 5px 5px  0px 5px;
      font-weight: 400;
      font-size: 18px;
    }
    span2{
       position: absolute;
      top: 5px;
      left: 5px;
      background-color: rgb(255, 255, 255);
      color: rgb(255, 0, 0);
      padding: 3px 5px;
      z-index: 3;
      border-radius: 5px 5px  0px 5px;
      font-weight: 400;
      font-size: 18px;

    }
    



  }

  
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
  }

  .data{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    p{
      font-size: 15px;
      margin-bottom: 5px !important;
      color: black !important;
      font-weight: 600;
    }
    small{
      color: grey !important;
      font-weight: 400 !important;
    }
    .prices {
    display: flex;
    gap: 20px;
    
    h1 {
      font-size: 30px;
      font-weight: 600 !important;
    }

    h2 {

      font-size: 18px;
      font-weight: 500;
      

      &:first-child {
        color:orange !important;
        text-decoration: line-through;
      }
    }
    
  }


  button{
    margin: 20px 0px;
      width: 150px !important;
    }

    .addtocart{
  display:block;
  padding:0.5em 1em 0.5em 1em;
  border-radius:100px;
  border:none;
  font-size:12px;
  position:relative;
  background:rgb(255, 166, 0);
  cursor:pointer;
  overflow:hidden;
  transition:transform 0.1s;
  z-index:1;
}
.addtocart:hover{
  transform:scale(1.1);
  color: white;
}
  }
}
