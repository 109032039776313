.products {
  
  padding: 30px 50px;
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  grid-gap: 2rem;
  width: 100%;
 

  .left {
    flex: 1;

     position: relative;
    height: 100%;
    top: 60px;
    padding-left: 10px;
  

    h2 {
      font-size: 400;
      margin-bottom: 10px;
    }
    .inputItem {
      margin-bottom: 5px;
      label {
        margin-left: 10px;
      }
    }

    .filterItem {
      top: 110px;
      margin-bottom: 30px;
      position: absolute;
      position: sticky;
      h2{
        font-size: 2rem;
        line-height: 1.2;
      }
    }
  }
  .right {
    flex: 3;
    .free_banner{
      width: 100%;
      overflow: hidden;
      border-radius: 10px;
      img{
        width: 100%;
      }
    }

    .catImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .products {
    padding: 30px;
    grid-template-columns: 1fr;
    .left {
      display: none;
    }
    .navbar-nav {
      width: 100%;
      padding-top: 10px;
    }
  }
}
