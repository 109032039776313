.almond_flour {
  width: 100%;
  .almond_intro {
    width: 100% !important;
    height: auto;
    background-color: #003087;
    display: flex;
    justify-content: center;
    align-items: center;
    .almond_intro_data {
      padding: 60px 100px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      h1 {
        color: white !important;
        padding-bottom: 10px;
        font-size: 3rem;
      }
      strong {
        padding: 1rem;
        font-size: 20px;
        font-weight: 300;
      }
      p {
        color: white;
        text-align: center;
      }
    }
  }
  .helth_be {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 80px 0px;
    width: 100%;
    height: auto;
    h1 {
      font-size: 3rem;
      color: #004690;
      line-height: normal;
    }
    .helth_be_in {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;

      .helth_icon_con {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .helth_icon {
        padding: 30px 0px;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      div {
        padding: 10px;
      }
    }

    .helth_img {
      img {
        width: 100%;
        height: auto;
      }
    }
    @media screen and (max-width: 1000px) {
      .helth_be_in {
        grid-template-columns: 1fr;
      }
      .helth_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
      }
      .helth_icon {
        padding: 5px !important;
      }
    }
  }

  .Alm_info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 130px;
    grid-gap: 3rem;

    .Alm_info_head {
      h1 {
        font-size: 3rem;
        line-height: 1;
        padding-bottom: 20px;
        color: #2b569f;
      }
      strong {
        font-size: 20px;
        font-weight: 300;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .Alm_info {
      grid-template-columns: 1fr;
      padding: 50px;
      text-align: center;
    }

    .almond_intro_data {
      padding: 50px !important;
      text-align: center !important;
    }
  }
  .almond {
    width: 100%;
    height: 60vh;

    object-fit: contain;
    align-items: center;
    justify-content: center;
    display: flex;
    h1 {
      font-size: 5rem;
      line-height: 1;
      text-align: center;
      color: #2b569f;
    }
  }
  .coco {
    width: 100%;
    height: 60vh;

    object-fit: contain;
    align-items: center;
    justify-content: center;
    display: flex;
    h1 {
      font-size: 5rem;
      line-height: 1;
      text-align: center;
      color: #159a56;
    }
  }

  .almond_ban {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .shop_button {
      height: auto;
      padding: 20px 0px;
      .btn {
        color: rgb(255, 255, 255) !important;
        border: none;
        background: white;
        padding: 10px 30px;
        border-radius: 30px;
        border: 2px solid white;
      }
      .btn:hover {
        background-color: rgba(255, 255, 255, 0);
        color: rgb(255, 255, 255) !important;
      }
    }
  }
}
