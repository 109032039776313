.home {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .__hero {
    width: 100% !important;
    max-width: 100vw !important;
    height: auto;
    overflow: hidden;
  }
  .com_div {
    padding: 50px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .com_head {
      text-align: center;
    }
  }
  @media screen and (max-width: 992px) {
    .com_div {
      padding: 10px 0px;
    }
  }

  .hero_wrappe {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 990;
    object-fit: contain;
  }

  .heading {
    display: flex;
    object-fit: contain;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: end;
    justify-content: center;
    padding-bottom: 20px;
    text-align: center;
    position: absolute;
    z-index: 999;

    p {
      color: white;
      line-height: 1;
      font-size: 12px;
    }
  }
  .heading-two {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    h1 {
      font-size: 2.5 rem;
      color: rgb(0, 0, 0);
    }
  }
  @media screen and (max-width: 992px) {
    .heading {
      padding-top: 30px !important;
      padding: 20px;
    }
  }

  .hero_wrappe {
    width: 100%;
    height: auto;
    height: 60vh;
    min-height: auto;
    overflow: hidden;
    video {
      object-fit: contain;
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    .hero_wrappe {
      height: auto;
    }
  }

  .product_info_section_one {
    padding: 50px 80px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    .text {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      h1 {
        padding-bottom: 20px;
        font-size: 3.5rem;
        color: #f19c26;
      }
      .strong {
        margin-bottom: 10px;
      }
    }
    .img {
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .btn {
      padding-top: 30px;
      button {
        border: none;
        padding: 10px 30px;
        border-radius: 30px;
        color: white;
      }
    }
  }

  .home_mission {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 100px;
    text-align: center;
    h1 {
      color: white;
      padding-bottom: 20px;
    }
    strong {
      color: white;
    }
    .card_button {
      height: auto;
      padding: 20px 0px;
      button {
        background-color: #fdb653;
        border: none;
        padding: 10px 30px;
        border-radius: 30px;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .home_mission {
      padding: 50px 20px !important;
    }
  }
  .testimonial {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    text-align: center;
    padding: 50px 100px;
    h1 {
      font-size: 3.5rem;
      color: rgb(241, 156, 38);
      padding-bottom: 30px;
    }
    .test_in {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 4rem;
      overflow: hidden;
      object-fit: contain;

      .test_vido {
        width: 100%;
        height: 100%;
        object-fit: contain;
        overflow: hidden;

        .videoTag {
          height: 300px;
          width: 100%;
          object-fit: contain;
          overflow: hidden;
          background-color: black;
          border-radius: 30px;

          source {
            object-fit: contain;
            overflow: hidden;
            width: 100%;
          }
        }
        @media only screen and (max-width: 992px) {
          .videoTag {
            padding: 20px;
            width: 80%;
          }
        }
      }
    }
    @media only screen and (max-width: 992px) {
      .test_in {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .testimonial {
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .home {
    align-items: center;
    justify-content: center;
    display: flex;

    .product_info_section_one {
      grid-template-columns: 1fr;
      padding: 0 !important;
      .text {
        width: 100%;
        padding: 20px;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      img {
        width: 100%;
        padding: 50px 50px;
        align-items: center;
        justify-content: center;
      }
    }

    .home_mission {
      width: 100%;
      object-fit: contain;
      img {
        width: 100%;
      }
    }
  }
}
