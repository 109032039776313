.featuredProducts {
  padding: 50px;
  width: auto;
  object-fit: contain;
  .heading {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      text-transform: capitalize;
      padding-bottom: 30px;
      font-size: 3.5rem;
    }
    p {
      flex: 3;
      color: gray;
    }
  }
  .bottom {
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr ;
    width: 100%;
    justify-content: center;
    grid-gap: 2rem;

  }
  @media screen and (max-width: 992px) {
    .bottom {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 992px) {
  .featuredProducts {
    padding: 20px !important;
  }
}
