.privacy {
  width: 100%;
  height: auto;
  padding: 50px 100px 100px 100px;
  h1 {
    font-size: 2.5rem;
  }
  section {
    width: 100%;
    padding: 20px 50px;
    h2 {
      color: #2b569f;
    }
    .item {
      width: 100%;
      padding: 0px 50px;
      span {
        font-weight: 600;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .privacy {
    padding: 40px;
    section {
      padding: 0px !important;
      .item {
        width: 100%;
        padding: 0px !important;
      }
    }
  }
}
