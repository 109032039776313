.categories {
  width: 100%;
  height: 80vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;


  .bigBoxOne {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    .img_con {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: rgb(241, 241, 241);
      padding: 20px;
      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        float:left;
      }
      img:hover{
        transform: scale(1.1);
        transition: .2s ease-in-out ;
      }
    }
  }
  .bigBoxTwo {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    height: auto;
    overflow: hidden;
     object-fit: cover;
    .img_con {
           background-color: rgb(241, 241, 241);
      object-fit: cover;
      width: 100%;
      height: auto;
      overflow: hidden;
      .img {
        display: flex;
        object-fit: cover;
      }
      img:hover{
        transform: scale(1.1);
        transition: .2s ease-in-out ;
      }
    }
  }
 
}
 @media screen and (max-width:992px) {
  .categories{
    grid-template-columns: 1fr !important;
    padding: 20px;

  }
  
}

