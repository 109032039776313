.map{
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    
    .map_wrap{
        padding-top: 40px;
        width: 100%;
        height: auto;
        overflow: hidden;
    }

}