@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;800&display=swap');

body {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 100px !important;

  font-smooth: inherit;
  font-smooth: initial;
  font-smooth: revert;
  font-smooth: revert-layer;
  font-smooth: unset;
  margin: 0;
  font-family: Domine -apple-system, BlinkMacSystemFont,'Jost', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}
/*
@font-face {
  font-family: "CustomFont";
  src: url("../src/fonts/SaolDisplay-Regular.ttf");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff") format("woff");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff2") format("woff2");
}
*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  color: #707070;
  line-height: 1.6;
}
h1,
h2,
h3,
h4,
h5,
h6,
small {
  
  color: #30373b !important;
  margin: 0;
  font-family: 'Domine', serif !important;
}
span1 {
  font-family: 'Domine', serif !important; 
  color: red;
  font-size: 2.5rem;
}
h1 {
  font-size: 2.5rem;
  font-weight: 300 !important;
}
span{
  line-height: 1 !important;
}

small {
  font-size: 18px;
  color: black;
}
a {
  text-decoration: none !important;
  color: black;
  font-weight: 500;
  font-size: 14px;
}
strong {
  font-weight: 300 !important;
  font-size: 25px;
  line-height: 1;
}
.max-width {
  width: 100% !important;
  height: auto;
  flex-direction: column;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  max-width: 1400px;
}
@media only screen and (max-width: 992px) {
  h1 {
    font-size: 2.5rem !important;
    line-height: 1 !important;
  }
  .max-width {
    width: 100%;
    max-width: none !important;
  }
}

@media only screen and (max-width: 992px) {
 body{
  margin-top: 100px !important;
 }
}


.checkout {
    width: 250px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
    border: none;
    background-color: #2879fe;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
    border-radius: 30px;
  }
  .checkout:hover {
    transition: all 0.3s ease-in-out;
    background-color: #7bbd42;
  }